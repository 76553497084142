import { LumEnvironmentInterface } from '@lum-types';
import { LUM_LOG_LEVEL } from '@lum-utils';
import packageInfo from '../../package.json';

export declare const LUM_BUILD_TIMESTAMP = 0;

export const LUM_ENV: LumEnvironmentInterface = {
  production: true,
  env: 'dev',
  version: packageInfo?.version,
  buildTimestamp: LUM_BUILD_TIMESTAMP,
  logLevel: LUM_LOG_LEVEL.All,
  api: {
    baseUrl: 'https://api.dev.connect.lumeso.com',
  },
  dcupl: {
    baseUrl: 'https://api.dev.connect.lumeso.com/dcupl',
    applicationKey: 'connect-lumeso',
  },
  cognito: {
    userPoolClientId: '1ng2v04905369p3npls837315j',
    userPoolId: 'eu-central-1_DcthDEmXE',
    loginWith: {
      oauth: {
        domain:
          'lumeso-authorizers-development.auth.eu-central-1.amazoncognito.com',
        scopes: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: ['https://dev.connect.lumeso.com/'],
        redirectSignOut: ['https://dev.connect.lumeso.com/login'],
        responseType: 'code',
      },
    },
  },
};
