<div class="bg-gray-100">
  <mat-sidenav-container>
    <mat-sidenav
      mode="over"
      position="end"
      [opened]="sidebarService.isOpen$()"
      (closed)="sidebarService.closeSidebar()"
      class="!w-full md:!w-[760px]"
    >
      <div class="relative w-full p-6 md:w-[760px]">
        <button
          type="button"
          (click)="sidebarService.closeSidebar()"
          class="absolute z-10 top-6 right-6"
          data-cy="btn-close-sidebar"
        >
          <lum-icon name="x-24"></lum-icon>
          <span class="sr-only">close sidebar</span>
        </button>

        <div #sidebarComponentRef></div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
