import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeCS from '@angular/common/locales/cs';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogAllModule } from '@lum-dialogs/dialog-all.module';
import { CustomMissingTranslationHandler } from '@lum-helpers';
import { ApiAuthInterceptor } from '@lum-interceptors';
import { LayoutModule } from '@lum-layout';
import { GlobalErrorHandlerModule } from '@lum-modules';
import { LUM_ENVIRONMENT } from '@lum-types';
import { ButtonModule } from '@lum-ui/button';
import { IconModule } from '@lum-ui/icon';
import {
  ToastMessageComponent,
  ToastMessageModule,
} from '@lum-ui/toast-message';
import { NdsModule } from '@netural/nds';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { LUM_ENV } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeEN);
registerLocaleData(localeDE);
registerLocaleData(localeCS);

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    IconModule,
    MatSidenavModule,
    NdsModule.forRoot({
      basePath: 'designsystem',
      storyblok: {
        version: 'draft',
        accessToken: '0sykE697bZcfs5PQTlXtHwtt', // TODO: this is the nds boilerplate token. so replace with your own token
      },
      logLevel: 'TRACE',
    }),
    DialogAllModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastMessageModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      disableTimeOut: 'extendedTimeOut',
      progressBar: true,
      progressAnimation: 'increasing',
      positionClass: 'toast-top-right',
      toastComponent: ToastMessageComponent,
    }),
    LayoutModule,
    GlobalErrorHandlerModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: LOCALE_ID, useValue: 'cs' },
    { provide: LUM_ENVIRONMENT, useValue: LUM_ENV },
    { provide: HTTP_INTERCEPTORS, useClass: ApiAuthInterceptor, multi: true },
  ],
})
export class AppModule {}
