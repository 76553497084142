import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LumAuthUser, LumUserRole } from '../types/user.type';

import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signInWithRedirect,
  signOut,
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { first } from 'lodash-es';
import { LumLogger } from '../utils/logger.utils';
import { StorageUtils } from '../utils/storage.utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$ = new BehaviorSubject<LumAuthUser | null>(null);

  constructor(private readonly router: Router) {
    this.initAuthService();
  }

  private async initAuthService(): Promise<void> {
    if (await this.isAuthenticated$()) {
      this.getCurrentUserInfo();
    }

    const lastRoute = StorageUtils.get<string>('lum.last-route');
    if (lastRoute) {
      StorageUtils.set('lum.last-route');
      this.router.navigate([lastRoute]);
    }

    Hub.listen('auth', async (data) => {
      const { payload } = data;
      if (payload.event === 'signedIn') {
        this.getCurrentUserInfo(payload.data);
        this.router.navigate(['/platform']);
      }
    });
  }

  public user$(): Observable<LumAuthUser | null> {
    return this._user$.asObservable();
  }

  public async isAuthenticated$(): Promise<boolean> {
    try {
      const token = await this.getAuthToken();
      return !!token;
    } catch (error) {
      LumLogger.error('Error checking if user is authenticated', error);
      return false;
    }
  }

  public async login(): Promise<void> {
    try {
      await signInWithRedirect();
    } catch (error) {
      LumLogger.error('Error logging in', error);
      const customError = error as Error;
      if (customError.name === 'UserAlreadyAuthenticatedException') {
        this.router.navigate(['/platform']);
      }
    }
  }

  public async logout(): Promise<void> {
    await signOut();
  }

  private async getCurrentUserInfo(data?: LumAuthUser): Promise<void> {
    try {
      const currentSession = await fetchAuthSession();
      const user = await getCurrentUser();
      const attributes = await fetchUserAttributes();

      const groups = currentSession?.tokens?.accessToken?.payload[
        'cognito:groups'
      ] as LumUserRole[];

      const companyId =
        currentSession?.tokens?.idToken?.payload?.['custom:company_id'];

      this._user$.next({
        username: user.username,
        email: attributes.email,
        role: first(groups),
        companyId: companyId ? Number(companyId) : undefined,
      });
    } catch (error) {
      LumLogger.error('Error getting user info', error, data);
      this._user$.next(data ?? null);
    }
  }

  public async getAuthToken(): Promise<string | undefined> {
    const currentSession = await fetchAuthSession();
    return currentSession.tokens?.accessToken?.toString();
  }
}
