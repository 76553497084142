import { Injectable } from '@angular/core';
import { LumNotificationSettings } from '@lum-types';
import { Observable, of } from 'rxjs';
import { BaseDataMockService } from '../../base-data/base-data.mock.service';
import { NotificationSettingsService } from './notification-settings.abstract';

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingsMockService
  extends BaseDataMockService<LumNotificationSettings & { id?: number }>
  implements NotificationSettingsService
{
  private notificationSettings: LumNotificationSettings[] = [
    {
      receiveClientEmails: true,
      receiveConnectorEmails: false,
      receivePlatformEmails: true,
    },
  ];

  constructor() {
    super();
    this._mockData = this.notificationSettings;
  }

  public getNotificationSettings(): Observable<LumNotificationSettings> {
    return this.getEntity();
  }

  public updateNotificationSettings(
    notificationSettings: LumNotificationSettings
  ): Observable<void> {
    this.notificationSettings = [notificationSettings];
    return this.delayResponse(of(void 0));
  }
}
