<div class="relative">
  <input
    [disabled]="isDisabled"
    class="form-input"
    [ngClass]="getClasses()"
    [type]="outputType"
    [id]="htmlId"
    [ngModel]="getValue()"
    (ngModelChange)="onChange($event)"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete"
    (blur)="onBlur()"
    [attr.data-cy]="'input-' + dataCy"
    #input
  />

  <lum-icon
    *ngIf="type === 'search'"
    name="search-16"
    [size]="16"
    class="absolute inset-y-0 px-2 my-auto pointer-events-none"
    [class.text-gray-400]="isDisabled"
  ></lum-icon>

  <button
    lum-button
    *ngIf="type === 'search'"
    class="absolute inset-y-0 right-0 pl-2 pr-1 my-auto"
    [ngClass]="{
      hidden: !input.value,
    }"
    icon="x-16"
    [iconSize]="16"
    variant="link"
    [isDisabled]="isDisabled"
    color="text-blue-700"
    (click)="clearSearch()"
  >
    <span class="sr-only">Clear search</span>
  </button>
</div>
