import {
  LumBusinessCaseSummaryBuildupEntry,
  LumBusinessCaseSummaryBuildupEntryProduct,
  LumBusinessRelationRole,
  LumClientBuildupTree,
  LumClientBuildupTreeProduct,
} from '@lum-types';
import { neverError } from '../never.helper';

export function getLabelByBuildupProductProperty(
  key:
    | keyof LumBusinessCaseSummaryBuildupEntryProduct
    | keyof LumClientBuildupTreeProduct
): string {
  switch (key) {
    case 'id':
      return 'ID';

    case 'productCategory':
      return 'lum.business-case.detail.data-view.header.productCategory';

    case 'customerProductId':
    case 'supplierProductId':
    case 'clientProductId':
      return 'lum.business-case.detail.data-view.header.my-productId';

    case 'customerProductDetails':
    case 'supplierProductDetails':
    case 'clientProductDetails':
      return 'lum.business-case.detail.data-view.header.my-productDescription';

    case 'platformProductId':
      return 'lum.business-case.detail.data-view.header.product-id';

    case 'platformProductDetails':
      return 'lum.business-case.detail.data-view.header.product-description';

    case 'alternativePlatformProductId':
      return 'Alternative Platform Product ID';

    default:
      throw neverError(key);
  }
}

export function convertBuildupEntryToBuildupTree(
  buildupEntry: LumBusinessCaseSummaryBuildupEntry | null,
  businessRelationRole: LumBusinessRelationRole
): LumClientBuildupTree | undefined {
  if (!buildupEntry?.buildupProducts.length) {
    return undefined;
  }

  const products: LumClientBuildupTreeProduct[] =
    buildupEntry.buildupProducts.map(({ product }) => {
      return {
        productCategory: product.productCategory,
        clientProductId:
          businessRelationRole === 'CUSTOMER'
            ? product.customerProductId
            : product.supplierProductId,
        clientProductDetails:
          businessRelationRole === 'CUSTOMER'
            ? product.customerProductDetails
            : product.supplierProductDetails,
        platformProductId: product.platformProductId!,
        platformProductDetails: product.platformProductDetails,
      };
    });

  const buildupTree: LumClientBuildupTree = {
    clientBuildupId:
      businessRelationRole === 'CUSTOMER'
        ? buildupEntry.customerBuildupId!
        : buildupEntry.supplierBuildupId!,
    products,
  };

  return buildupTree;
}
