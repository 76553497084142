import {
  ApplicationRef,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  signal,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  LumPreviousSidebar,
  LumSidebarComponent,
  LumSidebarComponentInstance,
  LumSidebarOptions,
  LumSidebarParams,
} from '@lum-types';
import { LumLogger } from '@lum-utils';
import { first, isEmpty } from 'lodash-es';

const SCROLL_LOCK_CLASS = 'overflow-hidden';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public isOpen$ = signal(false);
  private previousSidebars$ = signal<LumPreviousSidebar[]>([]);

  private renderer?: Renderer2;
  private sidebarRef?: ElementRef<HTMLDivElement>;
  private componentRef?: ComponentRef<unknown>;

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.listenToRouteChanges();
  }

  private listenToRouteChanges(): void {
    this.route.queryParams.subscribe((params) => {
      this.restoreSidebarFromUrl(params);
    });
  }

  private restoreSidebarFromUrl(params?: Params): void {
    const componentName = params?.['component'];
    if (componentName) {
      const sidebar = this.previousSidebars$()?.find(
        (sidebar) =>
          sidebar.component.name === componentName &&
          JSON.stringify(sidebar.params) === params?.['sidebarParams']
      );
      if (sidebar) {
        this.openSidebar(sidebar.component, sidebar.params);
      } else {
        this.updateQueryParamsInUrl();
      }
    }
  }

  public initSidebarRef(sidebarRef: ElementRef<HTMLDivElement>): void {
    this.sidebarRef = sidebarRef;
  }

  private openSidebar(
    component: LumSidebarComponent,
    params?: LumSidebarParams
  ): void {
    this.removeComponentFromSidebarRef();
    this.appendComponentDomElem(component);
    this.initiateComponentIO(params);
    this.addScrollLock();
    this.isOpen$.set(true);
  }

  public closeSidebar(): void {
    this.removeComponentFromSidebarRef();
    this.updateQueryParamsInUrl();
    this.removeScrollLock();
    this.isOpen$.set(false);
  }

  public appendComponentToSidebarRef(
    component: LumSidebarComponent,
    params?: LumSidebarParams,
    options?: LumSidebarOptions
  ): void {
    if (this.componentRef) {
      this.closeSidebar();
    }

    // push new sidebar to previous sidebars
    const previousSidebars = this.previousSidebars$();
    if (
      !previousSidebars?.find(
        (sidebar) =>
          sidebar.component.name === component.name && sidebar.params === params
      )
    ) {
      previousSidebars.push({ component, params, options });
    }
    this.previousSidebars$.set(previousSidebars);

    if (options?.shouldPersistInUrl) {
      this.persistParamsInUrl(component.name, params);
    } else {
      this.openSidebar(component, params);
    }
  }

  private appendComponentDomElem(component: Type<unknown>): void {
    const rootViewContainerRef = first(this.appRef.components)?.injector.get(
      ViewContainerRef
    );
    this.componentRef = rootViewContainerRef?.createComponent(component, {
      injector: this.injector,
    });

    const componentDomElem = first(
      (this.componentRef?.hostView as EmbeddedViewRef<Type<unknown>>).rootNodes
    ) as HTMLElement;

    this.sidebarRef?.nativeElement.appendChild(componentDomElem);
  }

  private initiateComponentIO(params?: LumSidebarParams): void {
    if (params) {
      const componentRefInstance = this.componentRef?.instance as
        | LumSidebarComponentInstance
        | undefined;

      if (componentRefInstance) {
        componentRefInstance.sidebarParams = params;
      }
    }
  }

  private removeComponentFromSidebarRef(): void {
    try {
      if (this.componentRef) {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef?.destroy();
      }
    } catch (error) {
      LumLogger.error('Error while removing component from sidebar', error);
    }
    this.componentRef = undefined;
  }

  private persistParamsInUrl(
    componentName?: string,
    params?: LumSidebarParams
  ): void {
    if (!componentName) {
      return;
    }

    const sidebarParams =
      !params || isEmpty(params) ? null : JSON.stringify(params);

    this.updateQueryParamsInUrl(componentName, sidebarParams);
  }

  private addScrollLock(): void {
    this.renderer?.addClass(document.body, SCROLL_LOCK_CLASS);
  }

  private removeScrollLock(): void {
    this.renderer?.removeClass(document.body, SCROLL_LOCK_CLASS);
  }

  private updateQueryParamsInUrl(
    componentName: string | null = null,
    sidebarParams: string | null = null
  ): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        component: componentName,
        sidebarParams,
      },
      queryParamsHandling: 'merge',
    });
  }
}
