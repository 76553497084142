import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  public activeTab$ = new Subject<number>();

  constructor() {}

  public resetTabs(): void {
    this.activeTab$.next(0);
  }
}
