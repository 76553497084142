import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LUM_ENV } from '@lum-environment';
import { IconsRegistry, SidebarService } from '@lum-services';
import { completeIconSet } from '@lum-types';
import { LanguageUtils, LumLogger } from '@lum-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lum-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidebarComponentRef')
  sidebarComponentRef?: ElementRef<HTMLDivElement>;

  constructor(
    private readonly iconRegistry: IconsRegistry,
    private readonly translateService: TranslateService,
    public readonly sidebarService: SidebarService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    if (this.sidebarComponentRef) {
      this.sidebarService.initSidebarRef(this.sidebarComponentRef);
    }
  }

  private init(): void {
    LumLogger.setLogLevel(LUM_ENV.logLevel);
    this.iconRegistry.registerIcons(completeIconSet);
    this.initLanguage();
  }

  private initLanguage(): void {
    const language = LanguageUtils.getCurrentLanguage();
    this.translateService.setDefaultLang(LanguageUtils.defaultLanguage);
    this.translateService.use(language);
  }
}
