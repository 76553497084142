<header class="p-4">
  @if (isAuthenticated$ | async) {
    <div class="flex justify-end">
      <button
        lum-button
        *ngIf="isAuthenticated$ | async"
        (click)="logout()"
        data-cy="header_btn-logout"
      >
        {{ 'lum.header.logout' | translate }}
      </button>
    </div>
  } @else {
    <img
      class="max-w-sm w-[126px] h-8 my-1"
      src="/assets/images/logo/lumeso-logo-blue.svg"
      height="32"
      width="129"
      alt="Lumeso Logo"
    />
  }
</header>
