<div class="relative js-multi-select">
  <div
    class="relative flex form-select py-[5px] pr-14"
    [ngClass]="{
      focus: isDropdownVisible,
      'disabled pointer-events-none': isDisabled,
    }"
  >
    <div class="absolute inset-0 p-2.5 pl-3 h-9">
      <div *ngIf="selectedOptions.length === 0" class="opacity-50">
        {{ 'lum.form.multi-select.placeholder' | translate }}
      </div>
    </div>
    <button
      class="absolute inset-0 w-full h-full cursor-pointer"
      type="button"
      (click)="onDropdownToggleClick()"
      [attr.data-cy]="'multi-select-' + dataCy + '-toggle'"
    >
      <span class="sr-only"> toggle dropdown </span>
    </button>
    <div #multiselectWrapperRef class="w-full">
      <div
        #multiselectInnerWrapperRef
        class="flex w-full gap-1 overflow-hidden"
      >
        <button
          #buttonRef
          type="button"
          *ngFor="
            let option of selectedOptions;
            let i = index;
            trackBy: trackByOption
          "
          class="relative flex items-center px-2.5 py-1 text-blue-500 truncate bg-white rounded-2xl border-blue-500 border-[1.5px] gap-x-1 shrink-0 multi-select-item"
          [ngClass]="{
            '!border-gray-400 !bg-gray-100 text-gray-400': isDisabled,
            'pointer-events-none opacity-0': !visibleButtons[i],
          }"
          (click)="onOptionClick(option)"
          [attr.data-cy]="'multi-select-' + dataCy + '-option-' + i"
        >
          <span class="typo-label-xs">{{ option.label }}</span>
          <lum-icon name="x-16" [size]="16"></lum-icon>
        </button>
      </div>
    </div>

    <button
      *ngIf="getInvisibleButtonsCount(); let count"
      (click)="onDropdownToggleClick()"
      type="button"
      class="absolute inset-y-0 flex items-center font-semibold text-blue-500 pointer-events-none right-8"
    >
      +{{ count }}
    </button>

    <lum-icon
      name="caret-down-16"
      [size]="16"
      class="absolute inset-y-0 right-0 px-2 my-auto transition-all pointer-events-none"
      [class.rotate-180]="isDropdownVisible"
    ></lum-icon>
  </div>

  <!-- dropdown list -->
  <div
    *ngIf="isDropdownVisible"
    class="absolute top-0 left-0 right-0 z-10 mt-9"
  >
    <div
      class="mt-2 overflow-y-auto bg-white border rounded-lg shadow-md max-h-64 border-gray-50"
    >
      <span *ngIf="!options?.length" class="text-sm text-gray-500">
        {{ 'lum.form.multi-select.no-options' | translate }}
      </span>

      <lum-select-option
        *ngFor="let option of options; trackBy: trackByOption"
        [option]="option"
        (selected)="onOptionClick(option)"
        [isSelected]="isSelected(option)"
        [attr.data-cy]="'multi-select-' + dataCy + '-option-' + option.key"
      >
        {{ option.label }}
        <span *ngIf="option.size">({{ option.size }})</span>
      </lum-select-option>
    </div>
  </div>
</div>
