import { Injectable } from '@angular/core';
import { LumPagination } from '@lum-types';
import { LumDataViewPagination, StorageUtils } from '@lum-utils';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  private page: { [key in LumDataViewPagination]?: LumPagination } = {};

  public setPage(key: LumDataViewPagination, pagination: LumPagination): void {
    this.page[key] = pagination;
  }

  public getPage(key: LumDataViewPagination): LumPagination {
    return (
      this.page[key] ?? {
        pageNumber: 0,
        pageSize: StorageUtils.get<number>(key) ?? 10,
      }
    );
  }

  public resetPages(): void {
    this.page = {};
  }

  public resetPage(key: LumDataViewPagination): void {
    this.page[key] = {
      pageNumber: 0,
      pageSize: StorageUtils.get<number>(key) ?? 10,
    };
  }
}
